import React from 'react';
import '../style/projectCover.scss';
import ProjectCover from '../components/projectCover';
import { myContext } from '../state/provider';

export default class ProjectsAll extends React.Component {
  render() {
    const { projects, typographies, lang } = this.props;

    // let arrayYearsDistinct = [];
    // let optionsYears = projects.map(({ node }, index) => {
    //   const year = new Date(node.frontmatter.date).getFullYear();
    //   if (arrayYearsDistinct.includes(year)) {
    //     return false;
    //   }
    //   arrayYearsDistinct.push(year);
    //   return (
    //     <option key={index} value={year}>
    //       {year}
    //     </option>
    //   );
    // });

    let projectsCovers = projects;
    // const placeholderTypo =
    //   lang === 'fr' ? '-- Filtre par typologie' : '-- Select typology';
    // const placeholderYear =
    //   lang === 'fr' ? '-- Filtre par année' : '-- Select year';

    return (
      <myContext.Consumer>
        {(context) => (
          <div className="row projects-all">
            <div className="projects-selects">
              {/* <select
                className="form-control form-control-sm"
                onChange={(e) => context.changeTypology(e.currentTarget.value)}
                value={context.typography}
              >
                <option value="0">{placeholderTypo}</option>
                {typographies.map(({ node }, index) => {
                  const typoValue = node.frontmatter.value_en
                    ? node.frontmatter.value_en
                    : node.frontmatter.value_fr;
                  return (
                    <option key={index} value={node.frontmatter.code}>
                      {typoValue}
                    </option>
                  );
                })}
              </select>
              <select
                className="form-control form-control-sm"
                onChange={(e) => context.changeYear(e.currentTarget.value)}
                value={context.year}
              >
                <option value="0">{placeholderYear}</option>
                {optionsYears}
              </select> */}
            </div>
            {projectsCovers
              .filter(({ node }) =>
                this.filterByTypography(node, context.typography)
              )
              .filter(({ node }) => this.filterYear(node, context.year))
              .map(({ node }, index) => {
                const { path, title_en, title_fr, cover } = node.frontmatter;
                const title = title_en ? title_en : title_fr;
                return (
                  <ProjectCover
                    lang={lang}
                    key={index}
                    title={title}
                    cover={cover}
                    path={path}
                  />
                );
              })}
          </div>
        )}
      </myContext.Consumer>
    );
  }

  /**
   * Retourne true si le projet est lié à la typologie sélectionnée
   */
  filterByTypography(node, typography) {
    const { projectTypographies } = node.frontmatter;
    if (
      typography !== '0' &&
      projectTypographies &&
      projectTypographies.includes(typography)
    ) {
      return true;
    }
    if (typography === '0') {
      return true;
    }
    return false;
  }

  /**
   * Retourne true si le projet est lié à l'année sélectionnée
   */
  filterYear(node, year) {
    const { date } = node.frontmatter;
    const yearProject = new Date(date).getFullYear();
    if (year === '0') {
      return true;
    }
    return yearProject === Number(year);
  }
}
