import React from "react"
import "../style/projectCover.scss"
import { Link } from "gatsby"
import Img from 'gatsby-image'

function ProjectCover(props) {
  if (props.title) {
    let img = null
    if (props.cover) {
      img = <Img className="project__cover" fluid={props.cover.childImageSharp.fluid} />
    }
    return <div className="project">
      <Link to={`/${props.lang}/projects/${props.path}`}>
        {img}
        <span className="project__title">{props.title}</span>
      </Link>
    </div>
  }
  return <div className="project project--emtpy" style={{ backgroundColor: `transparent` }}></div>
}

export default ProjectCover