import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/fr"
import ProjectsAll from "../components/projectsAll"

export default class IndexPage extends React.Component {

  render() {
    const { location, data } = this.props
    return <Layout location={location}>
      <ProjectsAll typographies={data.typos.edges} projects={data.projects.edges} lang="fr" />
    </Layout>
  }
} 

export const projectsAll = graphql`
  query {
    projects: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(projects/*)/"}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          frontmatter {
            path
            date
            title_fr
            projectTypographies
            cover {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    typos: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(typographies/*)/"}}, sort: {fields: frontmatter___value_fr, order: ASC}) {
      edges {
        node {
          frontmatter {
            code
            value_fr
          }
        }
      }
    }
  }
`